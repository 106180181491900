import axios from 'axios'

const API = 'productos-admin'

const Productos = {
    selects_admin(){
        return axios(`${API}/selects`)
    },
    agregar(model){
        return axios.post(`${API}/agregar-producto`,model)
    },
    cambiarTipoProducto(datos){
        return axios.post(`productos/cambiar-tipo`,datos)
    },
    actualizar(id_producto, model){
        return axios.post(`${API}/${id_producto}/editar-producto`,model)
    },
    imagenes(id_producto){
        return axios.get(`${API}/${id_producto}/imagenes`)
    },
    editarImagenProducto(id_producto,imagen){
        return axios.put(`${API}/${id_producto}/editar-imagen`,imagen)
    },
    add_imagenes(id_producto,model){
        return axios.post(`${API}/${id_producto}/add-imagenes`,model)
    },
    imagen_delete(id_producto,id_img){
        return axios.delete(`${API}/${id_producto}/delete-imagen/${id_img}`)
    },
    eliminar(id_producto, params){
        return axios.delete(`${API}/${id_producto}/delete`, { params } );
    },
    accionAnularStock(id_producto_stock){
        return axios.delete(`${API}/${id_producto_stock}/anular-stock`)
    },
    eliminarDetalleProducto(id_producto, params){
        return axios(`${API}/detalle-eliminar-producto/${id_producto}`, { params });
    },
    inactivarCedisProducto(payload){
        return axios.put(`${API}/${payload.id_producto}/${payload.id_cedis}/inactivar-cedis-producto`)
    },
    categorias(params){
        return axios(`categorias-admin/lista`,{params:params})
    },
    listar(params){
        return axios(`categorias-admin/productos`,{params:params})
    },


    cambio_estado(id_producto, params){
        return axios.put(`${API}/${id_producto}/change-estado`, params)
    },
    detalle(id, params={}){
        return axios(`${API}/${id}/detalle`,{params})
    },
    detalle_stock(id){
        return axios(`${API}/${id}/detalle-stock`)
    },
    actualizar_stock(model){
        return axios.put(`${API}/update-stock`,model)
    },
    actualizar_stock_minimo(model){
        return axios.put(`${API}/update-stock-minimo`,model)
    },
    change_uso_stock(model){
        return axios.put(`${API}/change-uso-stock`,model)
    },
    inventario_productos(params){
        return axios('productos/inventario', { params } )
    },
    info_producto(params){
        return axios('productos/info-producto', { params } )
    },
    historialCambioPrecio(id_cedis){
        return axios(`categorias-admin/cedis/${id_cedis}/lista-categorias`)
    },
    getProductosHistorialPrecio(id_cedis, id_categoria){
        return axios(`categorias-admin/cedis/${id_cedis}/categoria/${id_categoria}/productos/historial-precio`)
    },


    postImportarCambioPreciosProductos(form){
        return axios.post(`${API}/importar_precios`,form)
    },
    postCambioPreciosProductos(form){
        return axios.post(`${API}/cambio_precios`,form)
    },
    importarImagenesProductos(form){
        return axios.post(`${API}/upload/images`,form)
    },
    importarProductosMasivo(form){
        return axios.post(`${API}/agregar/masivo`,form)
    },
    checkImportacion(){
        return axios.get(`${API}/import/check`)
    },
    eliminarArchivoBorrador(){
        return axios.delete(`${API}/import`)
    },
    getDetalleImportacion(idCedis){
        return axios.get(`${API}/cedis/${idCedis}/import/detalle`)
    },
    getImpuetsosAndPvs(idCedis){
        return axios.get(`${API}/cedis/${idCedis}/selects`)
    },
    updateRowImportar(idCedis,form){
        return axios.put(`${API}/cedis/${idCedis}/import/update`,form)
    },
    updateCategorias(idCedis,form){
        return axios.put(`${API}/cedis/${idCedis}/import/update-categorias`,form)
    },
    updateEtiquetas(idCedis,form){
        return axios.put(`${API}/cedis/${idCedis}/import/update-etiquetas`,form)
    },
    guardarProductosMasivo(idCedis){
        return axios.post(`${API}/cedis/${idCedis}/importar`)
    },
    eliminarARegistroTemporal(id){
        return axios.delete(`${API}/importar/registro/${id}`)
    },

    putImportProductsUpdate(model){
        return axios.post(`${API}/cedis/import-data`, model)
    },
    getProveedoresCedis(idCedis){
        return axios.get(`${API}/cedis/${idCedis}/proveedores`)
    },
    putUpdateChange(idCedis, model){
        return axios.put(`${API}/cedis/${idCedis}/update/product`, model)
    }

}

export default Productos
