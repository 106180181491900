<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 120px);">
        <cargando v-if="loading" />
        <div class="row mx-0 align-items-center">
            <div class="btn-back" @click="$router.push({name: 'admin.cedis.productos'})">
                <i class="icon-left f-14" />
            </div>
            <div class="col-auto text-general f-600">
                Importar productos en la base de datos
            </div>
            <button type="button" class="btn-grey px-3 ml-auto mr-3" @click="eliminarBorrador">
                Descartar
            </button>
            <button tpye="button" class="btn-general mr-2" @click="$refs.modalConfirmar.toggle()">
                Importar productos
            </button>
        </div>
        <div class="bg-white br-12 p-3 mt-3">
            <div class="row mx-0 mb-2">
                <div class="col-auto px-0">
                    <button type="button" class="btn-grey f-14 px-4" @click="$refs.modalImportar.editar()">
                        Reemplazar archivo
                    </button>
                </div>
            </div>
            <div class="row mx-0 align-items-center">
                <div class="col-auto d-middle pl-0">
                    <i class="icon-ok-circled text-green" />
                    <span class="mx-2">
                        {{ nuevosRegistros }} nuevos productos a registrar
                    </span>
                </div>
                <div class="col-auto d-middle">
                    <i class="icon-cancel-circled-outline text-alert" />
                    <span class="mx-2">
                        {{ totalErrores }} Errores
                    </span>
                </div>
                <div class="col-auto d-middle">
                    <i class="icon-view-grid-outline text-general f-18" />
                    <span class="mx-2">
                        {{ nuevasCategorias }} nuevas categorías
                    </span>
                </div>
                <div class="col-auto d-middle">
                    <i class="icon-tag text-general f-18" />
                    <span class="mx-2">
                        {{ nuevasEtiquetas }} nuevas etiquetas
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white br-12 py-3 mt-3">
            <div class="row mx-0 px-3 mb-3">
                <div class="col-auto text-general f-18 f-600">
                    Productos a importar
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12 px-0">
                    <el-table :data="registros" header-row-class-name="text-general f-15 f-600" style="width: 100%" height="calc(100vh - 200px)">
                        <el-table-column class-name="text-center" width="80" fixed>
                            <template slot-scope="{row}">
                                <i class="icon-pencil-outline f-20 text-general cr-pointer" @click="$router.push({name: 'admin.cedis.importar.producto', params: {id_cedis: idCedis, producto:row}})" />
                            </template>
                        </el-table-column>
                        <el-table-column label="Barcode" min-width="180">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:130px;">
                                            <p v-show="row.barcode.existe">* Barcode repetido</p>
                                            <p v-show="row.barcode.vacio">* Barcode vacio</p>
                                        </div>
                                        <i v-show="row.barcode.existe || row.barcode.vacio" class="icon-alert-circled f-20 text-alert" />
                                    </el-tooltip>
                                    {{ row.barcode.barcode }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sku" label="SKU" min-width="180">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.sku }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Producto" min-width="200">
                            <template slot-scope="{row}">
                                <div>
                                    {{ row.nombre }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Presentacion" min-width="180">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:130px;">
                                            * Presentación vacio
                                        </div>
                                        <i v-show="row.presentacion.vacio" class="icon-alert-circled f-20 mr-2 text-alert" />
                                    </el-tooltip>
                                    {{ row.presentacion.presentacion }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="repetido" label="Repetido" min-width="90" align="center">
                            <template slot-scope="{row}">
                                <el-popover placement="top-start" title="Ya existe un producto con ese mismo nombre y presentación" width="300" trigger="hover">
                                    <div class="d-middle">
                                        <div class="position-relative">
                                            <img class="br-10 cr-pointer" height="70" width="70" :src="row.repetido.imagen" />
                                        </div>
                                        <div class="col px-2">
                                            <p class="nombre lh-18 f-500 text-general f-300">{{ row.repetido.nombre }}</p>
                                            <p class="text-general2">{{ row.repetido.presentacion }}</p>
                                        </div>
                                    </div>
                                    <i v-show="row.repetido.existe" slot="reference" class="icon-alert-circled f-20 text-alert" />
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column prop="descripcion" label="Descripción" min-width="200">
                            <template slot-scope="{row}">
                                <div class="row mx-0 tres-puntos">
                                    {{ row.descripcion }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Categoría 1" min-width="170">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:130px;">
                                            * Categoría vacia
                                        </div>
                                        <i v-show="row.categoria1.vacio" class="icon-alert-circled f-20 mr-2 text-alert" />
                                    </el-tooltip>
                                    {{ row.categoria1.categoria }}
                                    <div v-show="row.categoria1.nueva_categoria" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Sub Categoría 1" min-width="170">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.categoria1.subcategoria }}
                                    <div v-show="row.categoria1.nueva_subcategoria" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Categoría 2" min-width="170">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.categoria2.categoria }}
                                    <div v-show="row.categoria2.nueva_categoria" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Sub Categoría 2" min-width="170">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.categoria2.subcategoria }}
                                    <div v-show="row.categoria2.nueva_subcategoria" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Categoría 3" min-width="170">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.categoria3.categoria }}
                                    <div v-show="row.categoria3.nueva_categoria" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Sub Categoría 3" min-width="170">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.categoria3.subcategoria }}
                                    <div v-show="row.categoria3.nueva_subcategoria" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Categoría 4" min-width="170">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.categoria4.categoria }}
                                    <div v-show="row.categoria4.nueva_categoria" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Sub Categoría 4" min-width="170">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.categoria4.subcategoria }}
                                    <div v-show="row.categoria4.nueva_subcategoria" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Vender por" min-width="130">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:130px;">
                                            <p v-show="row.vender_por.vacio">* Vender por, vacío </p>
                                            <p v-show="row.vender_por.mal_escrito">* Vender por, está mal escrito</p>
                                        </div>
                                        <i v-show="row.vender_por.mal_escrito ||row.vender_por.vacio" class="icon-alert-circled f-20 mr-2 text-alert" />
                                    </el-tooltip>
                                    {{ row.vender_por.vender_por }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Cantidad mínima de venta" min-width="180" align="center">
                            <template slot-scope="{row}">
                                <div>
                                    {{ row.cantidad_minima }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Cantidad máxima de compra" min-width="180" align="center">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.cantidad_maxima }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Cantidad aumentada click +" min-width="180" align="center">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.cantidad_aumentada }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Unidad de medida de venta" min-width="180">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:150px;">
                                            <p v-show="row.unidad_medida.vacio">* Unidad de medida, vacío </p>
                                            <p v-show="!row.unidad_medida.existe">* Unidad de medida, no existe</p>
                                        </div>
                                        <i v-show="row.unidad_medida.vacio || !row.unidad_medida.existe" class="icon-alert-circled f-20 mr-2 text-alert" />
                                    </el-tooltip>
                                    {{ row.unidad_medida.unidad_medida }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Peso volumétrico" min-width="120" align="center">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:130px;">
                                            Peso volumétrico <br /> está mal escrito
                                        </div>
                                        <i v-show="!row.peso_volumetrico.existe" class="icon-alert-circled f-20 mr-2 text-alert" />
                                    </el-tooltip>
                                    {{ row.peso_volumetrico.peso_volumetrico }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Peso (Kg)" min-width="130" align="center">
                            <template slot-scope="{row}">
                                <div>
                                    {{ row.peso_kg }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="stockDisponible" label="Stock Disponible" min-width="130" align="center">
                            <template slot-scope="{row}">
                                <div>
                                    <i v-show="row.stock_disponible == 0" class="icon-alert-triangle text-orange f-18 mr-2" />
                                    {{ row.stock_disponible }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Costo Unidad" min-width="160" align="center">
                            <template slot-scope="{row}">
                                <div>
                                    <i v-show="row.costo_unidad == 0" class="icon-alert-triangle text-orange f-18 mr-2" />
                                    {{ convertMoney(row.costo_unidad) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Precio Unidad" min-width="160" align="center">
                            <template slot-scope="{row}">
                                <div>
                                    <i v-show="row.precio_unidad == 0" class="icon-alert-triangle text-orange f-18 mr-2" />
                                    {{ convertMoney(row.precio_unidad) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Venta sin stock" min-width="150" align="center">
                            <template slot-scope="{row}">
                                <div class=" text-center">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:130px;">
                                            <p v-show="row.venta_sin_stock.vacio">* Venta sin stock vacio </p>
                                            <p v-show="row.venta_sin_stock.mal_escrito">* Venta sin stock mal escrito</p>
                                        </div>
                                        <i v-show="row.venta_sin_stock.vacio || row.venta_sin_stock.mal_escrito" class="icon-alert-circled f-20 mr-2 text-alert" />
                                    </el-tooltip>
                                    {{ row.venta_sin_stock.venta_sin_stock }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Stock Minimo Cedis" min-width="160" align="center">
                            <template slot-scope="{row}">
                                <div>
                                    <i v-show="row.stock_minimo_cedis == 0" class="icon-alert-triangle text-orange f-18 mr-2" />
                                    {{ row.stock_minimo_cedis }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Stock mínimo Tendero" min-width="160" align="center">
                            <template slot-scope="{row}">
                                <div>
                                    <i v-show="row.stock_minimo_tienda == 0" class="icon-alert-triangle text-orange f-18 mr-2" />
                                    {{ row.stock_minimo_tienda }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Inventario Proveedor" min-width="150" align="center">
                            <template slot-scope="{row}">
                                <div class=" text-center">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:130px;">
                                            <p v-show="row.inventario_proveedor.vacio">* Inventario Proveedor vacio </p>
                                            <p v-show="row.inventario_proveedor.mal_escrito">* Inventario Proveedor mal escrito</p>
                                        </div>
                                        <i v-show="row.inventario_proveedor.vacio || row.inventario_proveedor.mal_escrito" class="icon-alert-circled f-20 mr-2 text-alert" />
                                    </el-tooltip>
                                    {{ row.inventario_proveedor.inventario_proveedor }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Proveedor" min-width="180">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:160px;">
                                            * Proveedor no existe ó no es del Cedis
                                        </div>
                                        <i v-show="!row.proveedor.existe" class="icon-alert-circled f-20 mr-2 text-alert" />
                                    </el-tooltip>
                                    {{ row.proveedor.proveedor }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Impuesto" min-width="180">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content" style="max-width:130px;">
                                            * impuesto no existe
                                        </div>
                                        <i v-show="!row.impuesto.existe" class="icon-alert-circled f-20 mr-2 text-alert" />
                                    </el-tooltip>
                                    {{ row.impuesto.impuesto }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Etiqueta 1" min-width="180">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.etiqueta_1.etiqueta_1 }}
                                    <div v-show="row.etiqueta_1.nuevo" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Etiqueta 2" min-width="180">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.etiqueta_2.etiqueta_2 }}
                                    <div v-show="row.etiqueta_2.nuevo" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Etiqueta 3" min-width="180">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    {{ row.etiqueta_3.etiqueta_3 }}
                                    <div v-show="row.etiqueta_3.nuevo" class="mn-pill bg-green text-white f-10 ml-1">
                                        Nuevo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column class-name="text-center" width="80">
                            <template slot-scope="scope">
                                <i class="icon-trash-empty text-danger f-20 cr-pointer" @click="eliminarTemporal(scope)" />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <modal-importar-productos-masivo ref="modalImportar" @update="getDetalle"/>
        <modal-confirmar ref="modalConfirmar" titulo="Confirmar Importacion" no-aceptar :cargado-modal="loading" adicional="Importar" @adicional="importarProductosMasivo()">
            <div class="row mx-0 justify-center">
                <div class="col-11 justify-center text-center">
                    <p class="text-general">Se almacenaran <b>{{ nuevosRegistros }}</b> nuevos productos</p>
                    <p class="text-general my-3">Esta acción puede tardar unos minutos por favor sea paciente.</p>
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <div class="col-11 justify-center text-center">
                    <p class="text-general">¿Está seguro de confirmar la importación?</p>
                </div>
            </div>
        </modal-confirmar>
    </section>
</template>

<script>
import Productos from '~/services/productos/productosAdmin'
export default {
    components:{
        modalImportarProductosMasivo: () => import('~/pages/productos/partials/modalImportarProductosMasivo')
    },
    data(){
        return {
            loading:false,
            registros:[],
            nuevasCategorias:0,
            nuevasEtiquetas:0,
            nuevosRegistros:0,
            totalErrores:0
        }
    },
    computed:{
        idCedis(){
            return this.$route.params.id_cedis
        }
    },
    created(){
        this.getDetalle()
    },
    methods:{
        async getDetalle(){
            try {
                this.loading = true
                const {data} = await Productos.getDetalleImportacion(this.idCedis)
                this.registros = data.registros
                this.nuevasCategorias = data.categorias
                this.nuevasEtiquetas = data.etiquetas
                this.nuevosRegistros = data.nuevos
                this.totalErrores = data.errores

            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false
            }
        },
        async importarProductosMasivo(){
            try {
                this.loading = true
                const {data} = await Productos.guardarProductosMasivo(this.idCedis)
                this.$refs.modalConfirmar.toggle()
                this.notificacion('Exito','Importación masiva de productos ejecutada correctamente','success')
                this.$router.push({name:'admin.cedis.productos',params:{id_cedis:this.idCedis}})
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async eliminarBorrador(){
            try {
                const {data} = await Productos.eliminarArchivoBorrador()
                this.notificacion('','Archivo eliminado del historial correctamente','success')
                this.$router.push({name:'admin.cedis.productos',params:{id_cedis:this.idCedis}})
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarTemporal(row){
            try {

                const {data} = await Productos.eliminarARegistroTemporal(row.row.id)
                this.notificacion('Eliminado','Registro eliminado correctamente','success')

                this.registros.splice(row.$index,1)

            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
